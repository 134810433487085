<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn
          @click="getLocation"
        >
          Pobierz lokalizację
        </v-btn>
        <v-card
          v-if="location.lat !== ''"
          class="mt-5"
        >
          <v-card-text>
            Lat: {{ location.lat }}
            <br>
            Lng: {{ location.lng }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="!isCamera"
          fab
          right
          absolute
          elevation="5"
          @click="runCamera"
        >
          <v-icon>
            mdi-power
          </v-icon>
        </v-btn>

        <v-btn
          v-if="isCamera"
          fab
          right
          absolute
          elevation="5"
          @click="closeCamera"
        >
          <v-icon>
            mdi-window-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" sm="6">
        <video
          v-show="isCamera"
          class="video"
          :class="facingMode === 'user' ? 'front' : ''"
          ref="video"
        />
        <canvas style="display: none" ref="canva" />

         <v-btn
          v-if="isCamera"
          right
          bottom
          fab
          @click="TakePhoto"
        >
          <v-icon>
            mdi-camera
          </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" sm="6">
        <photos-gallery class="gallery" :photos="photos" />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import PhotosGallery from '@/components/PhotosGallery';
  export default {
    name: 'HelloWorld',
    components: {
      PhotosGallery
    },
    data () {
      return {
        photos: [],
        mediaStream: null,
        videoDevices: [],
        facingMode: "environment",
        counter: 0,
        switchingCamera: false,
        isCamera: false,
        location: {
          lat: '',
          lng: ''
        }
      }
    },
    methods: {
      async runCamera(facingMode) {
        this.facingMode = facingMode;
        let video = this.$refs.video;
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
        });
        video.srcObject = this.mediaStream;
        this.isCamera = true
        return await video.play();
      },
      closeCamera() {
        this.mediaStream.getTracks().forEach(function(track) {
          track.stop();
        });
        this.isCamera = false
      },
      async StartRecording(facingMode) {
        this.facingMode = facingMode;
        let video = this.$refs.video;
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
        });
        video.srcObject = this.mediaStream;
        return await video.play();
      },
      async switchCamera() {
        this.switchingCamera = true;
        const tracks = this.mediaStream.getVideoTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        await this.StartRecording(
          this.facingMode === "environment" ? "user" : "environment"
        );
        this.switchingCamera = false;
      },
      async TakePhoto() {
        let video = this.$refs.video;
        let canva = this.$refs.canva;
        let width = video.videoWidth;
        let height = video.videoHeight;
        canva.width = width;
        canva.height = height;
        let ctx = canva.getContext("2d");
        ctx.save();
        if (this.facingMode === "user") {
          ctx.scale(-1, 1);
          ctx.drawImage(video, width * -1, 0, width, height);
        } else {
          ctx.drawImage(video, 0, 0);
        }
        ctx.restore();
        this.photos.push({
          id: this.counter++,
          src: canva.toDataURL("image/png"),
        });

      },
      getLocation() {
        this.$getLocation()
          .then(coordinates => {
            console.log(coordinates);
            this.location.lat = coordinates.lat
            this.location.lng = coordinates.lng
          });
      }
    }
  }
</script>

<style>
.video {
  width: 100%;
}
</style>